/* eslint-disable react/react-in-jsx-scope */
import PropTypes from 'prop-types';
import { useState } from 'react';
import '../ChatBox.css';
import { addLineBreaks, formattedDateTime } from '../../../constant/const';

const IncomingMsg = ({ msg, time }) => {
  const [displayTime, setDisplayTime] = useState(true);
  const dateTime = formattedDateTime(time);

  return (
    <div
      className="received-msg"
      onClick={() => setDisplayTime((prev) => !prev)}
    >
      <div className="received-msg-inbox">
        <p className="new-line">{addLineBreaks(msg)}</p>
        {displayTime && <span className="time">{dateTime}</span>}
      </div>
    </div>
  );
};

IncomingMsg.propTypes = {
  msg: PropTypes.string.isRequired,
  time: PropTypes.instanceOf(Date).isRequired,
};

export default IncomingMsg;
