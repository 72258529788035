/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import {
  AboutUs,
  Chef,
  FindUs,
  Footer,
  Gallery,
  Header,
  Intro,
  Laurels,
  SpecialMenu,
  ChatBot,
} from './container';
import { Navbar } from './components';
import './App.css';

const App = () => (
  <Router>
    <div>
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={(
            <>
              <Header />
              {/* <AboutUs /> */}
              <SpecialMenu />
              {/* <Chef /> */}
              {/* <Intro /> */}
              {/* <Laurels /> */}
              <Gallery />
              <FindUs />
              <Footer />
            </>
          )}
        />
        <Route path="/contact" element={<ChatBot />} />
      </Routes>
    </div>
  </Router>
);

export default App;
