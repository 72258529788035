/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BsSend } from 'react-icons/bs';
import IncomingMsg from './MsgTemplates/IncomingMsg';
import OutgoingMsg from './MsgTemplates/OutgoingMsg';

const ChatBot = () => {
  const uri = 'https://chatbot-two-ruddy.vercel.app/askChikaChino';
  const [msgs, setMsgs] = useState([]);
  const [question, setQuestion] = useState('');
  const msgInboxRef = useRef(null);

  const addMsg = (newMsg) => {
    setMsgs((prevMsgs) => [...prevMsgs, newMsg]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let msgTime = new Date();

    const outgoingMsg = {
      value: question,
      time: msgTime,
      incoming: 0,
    };

    addMsg(outgoingMsg);

    try {
      const response = await axios.post(uri, { question });
      msgTime = new Date();

      const str = response.data;
      const incomingMsg = {
        value: str,
        time: msgTime,
        incoming: 1,
      };
      addMsg(incomingMsg);
    } catch (error) {
      console.error('Error posting the question:', error);
    } finally {
      setQuestion('');
    }
  };

  useEffect(() => {
    const ask = async (query) => {
      try {
        const response = await axios.post(uri, {
          question: query,
        });
        const msgTime = new Date();

        const incomingMsg = {
          value: response.data,
          time: msgTime,
          incoming: 1,
        };

        addMsg(incomingMsg);
      } catch (error) {
        console.error('Error posting the question:', error.response.data);
      } finally {
        setQuestion('');
      }
    };
    ask('Hello');
  }, []);

  useEffect(() => {
    if (msgInboxRef.current) {
      msgInboxRef.current.scrollTop = msgInboxRef.current.scrollHeight;
    }
  }, [msgs]);

  return (
    <div className="app__bg app__wrapper_bot" id="contact" style={{ flexDirection: 'column' }}>
      <div className="msg-inbox" ref={msgInboxRef}>
        {msgs.length > 0
          && msgs.map((msg, index) => (msg.incoming ? (
            <IncomingMsg key={index} msg={msg.value} time={msg.time} />
          ) : (
            <OutgoingMsg key={index} msg={msg.value} time={msg.time} />
          )))}
      </div>
      <div className="msg-bottom">
        <form onSubmit={handleSubmit} className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Write message..."
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            required
          />

          <button type="submit" className="input-group-text send-icon">
            <BsSend />
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatBot;
