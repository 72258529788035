/* eslint-disable react/react-in-jsx-scope */
import PropTypes from 'prop-types';
import { useState } from 'react';
import '../ChatBox.css';
import { formattedDateTime } from '../../../constant/const';

const OutgoingMsg = ({ msg, time }) => {
  const [displayTime, setDisplayTime] = useState(true);
  const dateTime = formattedDateTime(time);

  return (
    <div
      className="outgoing-chats"
      onClick={() => setDisplayTime((prev) => !prev)}
    >
      <div className="outgoing-msg">
        <div className="outgoing-chats-msg">
          <p className="multi-msg">{msg}</p>
          {/* <span className="time">{dateTime}</span> */}
          {displayTime && <span className="time">{dateTime}</span>}
        </div>
      </div>
    </div>
  );
};

OutgoingMsg.propTypes = {
  msg: PropTypes.string.isRequired,
  time: PropTypes.instanceOf(Date).isRequired,
};

export default OutgoingMsg;
