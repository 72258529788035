export const formattedDateTime = (dateTime) => {
  let formattedTime = dateTime.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
  const month = dateTime.toLocaleString('default', { month: 'short' });
  const day = dateTime.getDate();

  formattedTime += ` | ${month} ${day}`;

  return formattedTime;
};

export const addLineBreaks = (text) => text.replace(/\n/g, '\n');

export const containsJsonArray = (str) => /\[.*\]/.test(str);
