import images from './images';

const wines = [
  {
    title: 'Chapel Hill Shiraz',
    price: '$56',
    tags: 'AU | Bottle',
  },
  {
    title: 'Catena Malbee',
    price: '$59',
    tags: 'AU | Bottle',
  },
  {
    title: 'La Vieillw Rose',
    price: '$44',
    tags: 'FR | 750 ml',
  },
  {
    title: 'Rhino Pale Ale',
    price: '$31',
    tags: 'CA | 750 ml',
  },
  {
    title: 'Irish Guinness',
    price: '$26',
    tags: 'IE | 750 ml',
  },
];

const steak = [
  {
    title: 'Bistro House Special Steak',
    price: 'Rs. 1495',
    tags: 'House Special Steack',
  },
  {
    title: 'Jalapeno Chicken',
    price: 'Rs. 1045',
    tags: 'Tender Chicken with Jalapeno Sauce',
  },
  {
    title: 'Moroccan Chicken',
    price: 'Rs. 1049',
    tags: 'Grilled Chicken with Moroccan Sauce',
  },
  {
    title: 'Mushroom Chicken',
    price: 'Rs. 1099',
    tags: 'Grilled Chicken with Mushroom Sauce',
  },
  {
    title: 'Black Pepper Chicken',
    price: 'Rs. 999',
    tags: 'Grilled Chicken with Black Pepper Sauce',
  },
];

const bbq = [
  {
    title: 'Grand BBQ Platter (4 Persons)',
    price: 'Rs. 3549',
    tags: 'Mixture of BBQ | KABAB | BATER | MEAT | RICE',
  },
  {
    title: 'Grand BBQ Platter (2 Persons)',
    price: 'Rs. 1999',
    tags: 'Mixture of BBQ | KABAB | BATER | MEAT | RICE',
  },
  {
    title: 'Chicken Malai Boti',
    price: 'Rs. 899',
    tags: '08 PCS',
  },
  {
    title: 'Chicken Tikka Boti',
    price: 'Rs. 849',
    tags: '08 PCS',
  },
  {
    title: 'Chicken Seekh Kabab',
    price: 'Rs. 849',
    tags: '04 PCS',
  },
];

const cocktails = [
  {
    title: 'Aperol Sprtiz',
    price: '$20',
    tags: 'Aperol | Villa Marchesi prosecco | soda | 30 ml',
  },
  {
    title: "Dark 'N' Stormy",
    price: '$16',
    tags: 'Dark rum | Ginger beer | Slice of lime',
  },
  {
    title: 'Daiquiri',
    price: '$10',
    tags: 'Rum | Citrus juice | Sugar',
  },
  {
    title: 'Old Fashioned',
    price: '$31',
    tags: 'Bourbon | Brown sugar | Angostura Bitters',
  },
  {
    title: 'Negroni',
    price: '$26',
    tags: 'Gin | Sweet Vermouth | Campari | Orange garnish',
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Bib Gourmond',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award01,
    title: 'Rising Star',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
];

export default { wines, cocktails, awards, steak, bbq };
